<template>
  <div>
    <b-card border-variant="danger" v-if="errore_caricamento">
      <b-row class="match-height">
        <b-col
          md="8"
        >
          <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
          <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
        </b-col>
        <b-col 
          md="4"
          class="text-center"
        >
          <b-button
          variant="primary"
          :to="{ name: 'home-agentscout' }"
          >
              <feather-icon
                  icon="CornerUpLeftIcon"
                  size="16"
                  class="mr-1"
              />Home
          </b-button>
        </b-col>
      </b-row>
        
    </b-card>
        
    <div class="text-center my-3" v-if="Caricato == false">
      <b-spinner  v-if="errore_caricamento == false"
          variant="primary"
          style="width: 3rem; height: 3rem;"
          label="loading"
      />
      <div class="pt-1 text-primary" style="display:block;">Caricamento</div>
    </div>

    <b-card bg-variant="Default" v-if="Caricato">
      <b-card-body class="px-0 py-0">
        <b-tabs pills>
          <b-tab active @click="onTabChangeAttivi">
            <template #title>
              <feather-icon
              icon="AwardIcon"
              size="16"
              class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Attivi</span>
            </template>
            <b-row class="match-height">
              <b-col
                md="10"
                cols="8" 
                class="mb-2"
              >
                <!-- input search -->
                <b-input-group>
                  <b-input-group-prepend>
                    <b-form-select style="border-top-right-radius:0px; border-bottom-right-radius:0px;" v-model="campiRicerca.sezione" :options="options_ricerca" disabled-field="notEnabled"></b-form-select>
                  </b-input-group-prepend>
                    <b-form-input v-model="campiRicerca.nome"
                        placeholder="ricerca generale"
                        type="text" />
                  <b-input-group-append>
                    <b-button variant="outline-primary" @click.prevent="ricercaGenerale('Attivi')">
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-append>
      
                </b-input-group>
              </b-col>
              <b-col
                md="1"
                cols="2"
                class="mb-2"
              >
                <b-button variant="outline-primary" class="px-0" @click.prevent="clickReset('Attivi')" block v-if="resetState != 'hidden'"><feather-icon icon="DeleteIcon" /></b-button>
              </b-col>
              <b-col
                md="1"
                cols="2"
                class="mb-2"
              >
                <b-button variant="outline-primary" class="px-0" @click="sidebarAdvancedSearch('Attivi')" block v-b-tooltip.hover.v-primary title="Ricerca Avanzata"><feather-icon icon="ZoomInIcon" /></b-button>
              </b-col>
              
            </b-row>
    
            <!-- table -->
            <vue-good-table
            mode="remote"
            ref="table_Attivi"
            @on-page-change="onPageChange_Attivi"
            @on-sort-change="onSortChange_Attivi"
            @on-column-filter="onColumnFilter_Attivi"
            @on-per-page-change="onPerPageChange_Attivi"
            :totalRows="totalRecords"
            :rows="rows"
            :columns="columns"
            :isLoading=isLoading
            :search-options="{ 
                enabled: true,
                externalQuery: searchTerm }"
            :pagination-options="{
                enabled: true,
                perPage:pageLength
            }"
            >
            
            <template slot="loadingContent">
              <div class="text-center my-3">
                  <b-spinner
                    variant="primary"
                    style="width: 3rem; height: 3rem;"
                    label="loading"
                  />
                  <div class="d-block pt-1 text-primary">Caricamento</div>
              </div>
            </template>
            
            <div slot="emptystate"> <div class="text-center py-4"> Nessun agente presente</div> </div>
    
            <template
                slot="table-row"
                slot-scope="props"
            >

                <!-- Column: azioni -->
                <span v-if="props.column.field === 'azioni'">
                  <div class="text-nowrap">
                    <feather-icon
                      icon="ArrowRightIcon"
                      class="cursor-pointer text-primary"
                      size="21"
                      v-b-tooltip.hover.v-primary
                      title="Azioni"
                      :id="`tabellautenti-riga-${props.row.id}-sidebar-azioni`"
                      @click="sidebarAction(props.row.id, props.row.name+' '+props.row.surname, props.row.email, props.row.mobile, props.row.province.name, props.row.align.name, props.row.vat, props.row.enasarco, props.row.deleted_at)"
                    />
                  </div>
                </span>
    
                <!-- Column: standard -->
                <span v-else>
                {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
    
            <!-- pagination -->
            <template
                slot="pagination-bottom"
                slot-scope="props"
            >
                <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap">
                    Visualizzazione da 1 a 
                    </span>
                    <b-form-select
                    v-model="pageLength"
                    :options="['10','25','50','100']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap "> di {{ props.total }} righe </span>
                </div>
                <div>
                    <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                    <template #prev-text>
                        <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                        />
                    </template>
                    </b-pagination>
                </div>
                </div>
            </template>
            </vue-good-table>
          </b-tab>

          <b-tab @click="onTabChangeCancellati">
            <template #title>
                <feather-icon
                icon="AwardIcon"
                size="16"
                class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Cancellati</span>
            </template>

            <b-row class="match-height">
              <b-col
                md="10"
                cols="8" 
                class="mb-2"
              >
                <!-- input search -->
                <b-input-group>
                  <b-input-group-prepend>
                    <b-form-select style="border-top-right-radius:0px; border-bottom-right-radius:0px;" v-model="campiRicerca_cancellati.sezione" :options="options_ricerca_cancellati" disabled-field="notEnabled"></b-form-select>
                  </b-input-group-prepend>
                    <b-form-input v-model="campiRicerca_cancellati.nome"
                        placeholder="ricerca generale"
                        type="text" />
                  <b-input-group-append>
                    <b-button variant="outline-primary" @click.prevent="ricercaGenerale('Cancellati')">
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-append>
      
                </b-input-group>
              </b-col>
              <b-col
                md="1"
                cols="2"
                class="mb-2"
              >
                <b-button variant="outline-primary" class="px-0" @click.prevent="clickReset('Cancellati')" block v-if="resetState_cancellati != 'hidden'"><feather-icon icon="DeleteIcon" /></b-button>
              </b-col>
              <b-col
                md="1"
                cols="2"
                class="mb-2"
              >
                <b-button variant="outline-primary" class="px-0" @click="sidebarAdvancedSearch('Cancellati')" block v-b-tooltip.hover.v-primary title="Ricerca Avanzata"><feather-icon icon="ZoomInIcon" /></b-button>
              </b-col>
              
            </b-row>
            
            <vue-good-table
              mode="remote"
              ref="table_Cancellati"
              @on-page-change="onPageChange_Cancellati"
              @on-sort-change="onSortChange_Cancellati"
              @on-column-filter="onColumnFilter_Cancellati"
              @on-per-page-change="onPerPageChange_Cancellati"
              :totalRows="totalRecords_cancellati"
              :columns="columns_cancellati"
              :rows="rows_cancellati"
              :isLoading=isLoading
              :search-options="{
                  enabled: true,
                  externalQuery: searchTerm_cancellati }"
              :pagination-options="{
                  enabled: true,
                  perPage:pageLength_cancellati
              }"
              >
            
              <template slot="loadingContent">
                <div class="text-center my-3">
                    <b-spinner
                      variant="primary"
                      style="width: 3rem; height: 3rem;"
                      label="loading"
                    />
                    <div class="d-block pt-1 text-primary">Caricamento</div>
                </div>
              </template>
              
              <div slot="emptystate"> <div class="text-center py-4"> Nessuna licenza presente</div> </div>
              
              <template
                  slot="table-row"
                  slot-scope="props"
              >
                  <!-- Column: azioni -->
                  <span v-if="props.column.field === 'azioni'">
                    <div class="text-nowrap">
                      <feather-icon
                        icon="ArrowRightIcon"
                        class="cursor-pointer text-primary"
                        size="21"
                        v-b-tooltip.hover.v-primary
                        title="Azioni"
                        :id="`tabella-cancellati-riga-${props.row.id}-sidebar-azioni`"
                        @click="sidebarAction(props.row.id, props.row.name+' '+props.row.surname, props.row.email, props.row.mobile, props.row.province.name, props.row.align.name, props.row.vat, props.row.enasarco, props.row.deleted_at)"
                      />
                    </div>
                  </span>

                  <!-- Column: Common -->
                  <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                  </span>
              </template>

              <!-- pagination -->
              <template
                  slot="pagination-bottom"
                  slot-scope="props"
              >
                  <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap">
                      Visualizzazione da 1 a 
                      </span>
                      <b-form-select
                      v-model="pageLength_cancellati"
                      :options="['3','5','10']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                      />
                      <span class="text-nowrap "> di {{ props.total }} righe </span>
                  </div>
                  <div>
                      <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength_cancellati"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                      >
                      <template #prev-text>
                          <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                          />
                      </template>
                      <template #next-text>
                          <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                          />
                      </template>
                      </b-pagination>
                  </div>
                  </div>
              </template>
              </vue-good-table>

          </b-tab>

        </b-tabs>
      </b-card-body>
    </b-card>

    <b-sidebar
      ref="sidebar_advanced_search"
      id="sidebar-advanced-search"
      bg-variant="white"
      :title="titoloRicercaAvanzata"
      right
      backdrop
      shadow
      width="90% !important"
    >
      <div class="text-center my-3" v-if="Caricato_Sidebar_ricerca_avanzata == false">
        <b-spinner
          variant="primary"
          style="width: 3rem; height: 3rem;"
          label="loading"
        />
        <div class="pt-1 text-primary" style="display:block;">Caricamento</div>
      </div> 
      
      <div v-else>
        <b-form class="p-2" @submit.prevent>
          <b-row>
            <b-col cols="12" lg="6">
              <b-form-group
                label="Allineamento"
                label-for="v-adse-allineamento"
              >
                <multiselect
                  v-model="campiRicercaAvanzata.allineamento"
                  :options="optionsAllineamento"
                  :custom-label="multiSelect_act_Lingua" 
                  placeholder="seleziona"
                  :multiple="true"
                  :close-on-select="false" 
                  :clear-on-select="false"
                  :preserve-search="false"
                  :show-labels="false"
                  :max-height="150"
                  :searchable="false"
                  label="lingue" 
                  track-by="text" 
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" lg="6">
              <b-form-group
                label="Profilo"
                label-for="v-adse-profilo"
              >
                <multiselect
                  v-model="campiRicercaAvanzata.profilo"
                  :options="optionsProfilo"
                  :custom-label="multiSelect_act_Lingua" 
                  placeholder="seleziona o ricerca"
                  :multiple="true"
                  :close-on-select="false" 
                  :clear-on-select="false"
                  :preserve-search="true"
                  :show-labels="false"
                  :max-height="250"
                  :max="5"
                  group-values="items"
                  group-label="groupname" 
                  :group-select="true" 
                  label="profilo" 
                  track-by="text" 
                >
                  <template #optiongroup="slotProps">
                    <div>{{ slotProps.option.label }}</div>
                  </template>
                  <span slot="noResult">Nessun risultato</span>
                  <span slot="maxElements">Limite opzioni selezionabili raggiunto</span>
                </multiselect>
              </b-form-group>
            </b-col>

            <b-col cols="12" lg="6">
              <b-form-group
                label="Settore"
                label-for="v-adse-settore"
              >
                <multiselect
                  v-model="campiRicercaAvanzata.settore"
                  :options="optionsSettori"
                  :custom-label="multiSelect_act_Lingua" 
                  placeholder="seleziona o ricerca"
                  :multiple="true"
                  :close-on-select="false" 
                  :clear-on-select="false"
                  :preserve-search="true"
                  :show-labels="false"
                  :max-height="250"
                  :max="5"
                  group-values="items"
                  group-label="groupname" 
                  :group-select="true" 
                  label="settore" 
                  track-by="text" 
                >
                  <template #optiongroup="slotProps">
                    <div>{{ slotProps.option.label }}</div>
                  </template>
                  <span slot="noResult">Nessun risultato</span>
                  <span slot="maxElements">Limite opzioni selezionabili raggiunto</span>
                </multiselect>
              </b-form-group>
            </b-col>

            <b-col cols="12" lg="6">
              <b-form-group
                label="Segmento"
                label-for="v-adse-segmento"
              >
                <multiselect
                  v-model="campiRicercaAvanzata.segmento"
                  :options="optionsSegmento"
                  :custom-label="multiSelect_act_Lingua" 
                  placeholder="seleziona o ricerca"
                  :multiple="true"
                  :close-on-select="false" 
                  :clear-on-select="false"
                  :preserve-search="true"
                  :show-labels="false"
                  :max-height="250"
                  :max="5"
                  label="segmento" 
                  track-by="text" 
                >
                  <span slot="noResult">Nessun risultato</span>
                  <span slot="maxElements">Limite opzioni selezionabili raggiunto</span>
                </multiselect>
              </b-form-group>
            </b-col>

            <b-col cols="12" lg="6">
              <b-form-group
                label="Regioni"
                label-for="v-adse-regioni"
              >
                <multiselect
                  v-model="campiRicercaAvanzata.regioni"
                  :options="optionsRegioni"
                  :custom-label="multiSelect_act_Lingua" 
                  placeholder="seleziona o ricerca"
                  :multiple="true"
                  :close-on-select="false" 
                  :clear-on-select="false"
                  :preserve-search="true"
                  :show-labels="false"
                  :max-height="250"
                  :max="5"
                  label="regioni" 
                  track-by="text" 
                >
                  <span slot="noResult">Nessun risultato</span>
                  <span slot="maxElements">Limite opzioni selezionabili raggiunto</span>
                </multiselect>
              </b-form-group>
            </b-col>

            <b-col cols="12" lg="6">
              <b-form-group
                label="Province"
                label-for="v-adse-province"
              >
                <multiselect
                  v-model="campiRicercaAvanzata.province"
                  :options="optionsProvince"
                  :custom-label="multiSelect_act_Lingua" 
                  placeholder="seleziona o ricerca"
                  :multiple="true"
                  :close-on-select="false" 
                  :clear-on-select="false"
                  :preserve-search="true"
                  :show-labels="false"
                  :max-height="250"
                  :max="5"
                  label="province" 
                  track-by="text" 
                >
                  <span slot="noResult">Nessun risultato</span>
                  <span slot="maxElements">Limite opzioni selezionabili raggiunto</span>
                </multiselect>
              </b-form-group>
            </b-col>

            <b-col cols="12" lg="6">
              <b-form-group
                label="Cap"
                label-for="v-adse-cap"
              >
                <multiselect 
                  v-model="campiRicercaAvanzata.cap" 
                  id="cap" 
                  label="cap" 
                  track-by="value" 
                  placeholder="ricerca" 
                  open-direction="bottom" 
                  :options="lista_cap" 
                  :multiple="true" 
                  :searchable="true" 
                  :loading="isLoadingCap" 
                  :internal-search="false" 
                  :clear-on-select="false" 
                  :close-on-select="false" 
                  :options-limit="10"
                  :max-height="250" 
                  :show-no-results="false" 
                  :hide-selected="false" 
                  :show-labels="false" 
                  :custom-label="multiSelect_act_Lingua" 
                  @search-change="asyncFindCap">
                    <span slot="maxElements">Limite opzioni selezionabili raggiunto</span>
                    <span slot="noResult">Nessun risultato</span>
                </multiselect>
              </b-form-group>
            </b-col>

            
            <b-col cols="12" lg="2">
              <b-card-text class="mb-0">Enasarco</b-card-text>
              <b-form-checkbox
                checked="false"
                name="check-button"
                switch
                id="v-adse-enasarco"
                v-model="campiRicercaAvanzata.enasarco"
              ></b-form-checkbox>
            </b-col>

            <b-col cols="12" lg="2">
              <b-card-text class="mb-0">Partita Iva</b-card-text>
              <b-form-checkbox
                checked="false"
                name="check-button"
                switch
                inline
                id="v-adse-vat"
                v-model="campiRicercaAvanzata.vat"
              ></b-form-checkbox>
            </b-col>

            <b-col cols="12" lg="6">
              <b-form-group
                label="Aziende"
                label-for="v-adse-aziende"
              >
              <multiselect 
                  v-model="campiRicercaAvanzata.aziende" 
                  id="aziende" 
                  label="aziende" 
                  track-by="value" 
                  placeholder="ricerca" 
                  open-direction="bottom" 
                  :options="lista_aziende" 
                  :multiple="true" 
                  :searchable="true" 
                  :loading="isLoadingAziende" 
                  :internal-search="true" 
                  :clear-on-select="false" 
                  :close-on-select="false" 
                  :options-limit="10"
                  :max-height="250" 
                  :show-no-results="false" 
                  :hide-selected="false" 
                  :show-labels="false" 
                  :custom-label="multiSelect_act_Lingua" 
                  @search-change="asyncFindAziende">
                    <span slot="maxElements">Limite opzioni selezionabili raggiunto</span>
                    <span slot="noResult">Nessun risultato</span>
                </multiselect>
              </b-form-group>
            </b-col>

            <b-col cols="12" lg="6">
              <b-form-group
                label="Lingue"
                label-for="v-adse-lingue"
              >
                <multiselect
                  v-model="campiRicercaAvanzata.lingue"
                  :options="optionsLang"
                  :custom-label="multiSelect_act_Lingua" 
                  placeholder="seleziona o ricerca"
                  :multiple="true"
                  :close-on-select="false" 
                  :clear-on-select="false"
                  :preserve-search="true"
                  :show-labels="false"
                  :max-height="150"
                  :max="3"
                  label="lingue" 
                  track-by="text" 
                >
                  <span slot="noResult">Nessun risultato</span>
                  <span slot="maxElements">Limite opzioni selezionabili raggiunto</span>
                </multiselect>
              </b-form-group>
            </b-col>

            <b-col cols="12" lg="6">
              <b-form-group
                label="Titolo di studio"
                label-for="v-adse-titolodistudio"
              >
                <multiselect
                  v-model="campiRicercaAvanzata.titolodistudio"
                  :options="optionsTitoloDiStudio"
                  :custom-label="multiSelect_act_Lingua" 
                  placeholder="seleziona o ricerca"
                  :multiple="true"
                  :close-on-select="false" 
                  :clear-on-select="false"
                  :preserve-search="true"
                  :show-labels="false"
                  :max-height="250"
                  :max="5"
                  group-values="items"
                  group-label="groupname" 
                  :group-select="true" 
                  label="titolodistudio" 
                  track-by="text" 
                >
                  <template #optiongroup="slotProps">
                    <div>{{ slotProps.option.label }}</div>
                  </template>
                  <span slot="noResult">Nessun risultato</span>
                  <span slot="maxElements">Limite opzioni selezionabili raggiunto</span>
                </multiselect>
              </b-form-group>
            </b-col>

            <b-col cols="12" lg="6">
              <b-form-group
                label="Data Ultimo Aggiornamento cv"
                label-for="v-adse-lingue"
              >
                <multiselect
                  v-model="campiRicercaAvanzata.data_ultimo_aggiornamento_cv"
                  :options="options"
                  :custom-label="nameWithDays" 
                  placeholder="seleziona"
                  :multiple="false"
                  :close-on-select="true" 
                  :clear-on-select="false"
                  :preserve-search="true"
                  :show-labels="false"
                  label="data_ultimo_aggiornamento_cv" 
                  track-by="days" 
                />
              </b-form-group>  
            </b-col>
            
            <b-col cols="12" class="mt-2 text-center">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click.prevent="ricercaAvanzataGo"
              >
                Ricerca
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click.prevent="vuotaRicercaAvanzata"
              >
                Annulla
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-sidebar>

    <b-sidebar
      ref="sidebar_azioni"
      id="sidebar-right"
      bg-variant="white"
      :title="infoSideBar.titolo"
      right
      backdrop
      shadow
    >
      <div>
        <div class="py-0 px-2">
          
          <div class="divider">
              <div class="divider-text text-primary fw-bolder">DETTAGLI</div>
          </div>
          <b-row cols="1">
            <b-col class="riga_sidebar">
              <span class="font-weight-bold">Email:</span> {{ infoSideBar.email }}
            </b-col>
            <b-col class="riga_sidebar">
              <span class="font-weight-bold">Cellulare:</span> {{ infoSideBar.cellulare }}
            </b-col>
            <b-col class="riga_sidebar">
              <span class="font-weight-bold">Provincia:</span> {{ infoSideBar.provincia }}
            </b-col>
            <b-col class="riga_sidebar">
              <span class="font-weight-bold">Allineamento:</span> {{ infoSideBar.align }}
            </b-col>
            <b-col class="riga_sidebar">
              <span class="font-weight-bold">P.Iva:</span> <b-badge variant="light-success" v-if="infoSideBar.vat === true">Si</b-badge><b-badge variant="light-danger" v-else>No</b-badge>
            </b-col>
            <b-col class="riga_sidebar">
              <span class="font-weight-bold">Enasarco:</span> <b-badge variant="light-success" v-if="infoSideBar.enasarco === true">Si</b-badge><b-badge variant="light-danger" v-else>No</b-badge>
            </b-col>
          </b-row>

          <div class="divider">
            <div class="divider-text text-primary fw-bolder mt-2">AZIONI</div>
          </div>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary" block
            class="mb-1"
            @click="$router.push({ name: 'crm-agenti-show', params: { id_riga: infoSideBar.id }})"
          >
            <feather-icon icon="EyeIcon" /> Visualizza
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-warning" block
            class="mb-1"
            @click="$router.push({ name: 'crm-agenti-edit', params: { id_riga: infoSideBar.id }})"
          >
            <feather-icon icon="Edit2Icon" /> Modifica
          </b-button>
          <b-button
            v-if="infoSideBar.deleted_at"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-warning" block
            class="mb-1"
            @click="ripristinaRiga(infoSideBar.id)"
          >
            <feather-icon icon="RefreshCcwIcon" /> Riattiva agente
          </b-button>
          <b-button
            v-else
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger" block
            class="mb-1"
            @click="cancellaRiga(infoSideBar.id)"
          >
            <feather-icon icon="Trash2Icon" /> Cancella
          </b-button>

          <newactivity field_tipo="Agent" :field_id="infoSideBar.id" :field_nome_relazione="infoSideBar.titolo"></newactivity>

        </div>

      </div>
    </b-sidebar>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTabs, BTab, BCard, BCardBody, BCardText, BRow, BCol, BBadge, BPagination, BSpinner, BFormGroup, BInputGroup, BFormInput, BFormCheckbox, BForm, BInputGroupPrepend, BInputGroupAppend, BDropdownDivider, BFormSelect, BDropdown, BDropdownItem, BButton, BSidebar, VBToggle, VBTooltip, BOverlay
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import Multiselect from 'vue-multiselect' 
import newactivity from '@core/components/newactivity/newactivity.vue'

export default {
  components: {
    BTabs, 
    BTab, 
    BCardCode,
    VueGoodTable,
    BCard, 
    BCardBody, 
    BCardText,
    BRow, 
    BCol, 
    BBadge,
    BPagination,
    BSpinner,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdownDivider,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BButton,
    BOverlay,
    Multiselect,

    newactivity,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      /* Attivi */
      isLoading: true,
      emptystate: true, 
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Nome',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Cognome',
          field: 'surname',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Cellulare',
          field: 'mobile',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Provincia',
          field: 'province.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Data Reg.',
          field: 'datatime_agent_reg',
          width: '80px',
          formatFn: function (value) {
              return value != null ? moment(String(value)).locale('it').format('DD/MM/YYYY') : '---'
          },
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Provenienza',
          field: 'provenance.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Allineamento',
          field: 'align.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },

        {
          label: '',
          field: 'azioni',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      rows: [],
      searchTerm: '',
      resetState: 'hidden',
      totalRecords: 0,
      serverParams: {
        columnFilters: {
          "deleted_at": {"operator": "=", "value": null }
        },
        sort: [
            {
            field: 'id',
            type: 'desc'
            }
        ],
        page: 1, 
        perPage: 10
      },
      startServerParams: {
        columnFilters: {
          "deleted_at": {"operator": "=", "value": null }
        },
        sort: [
            {
            field: 'id',
            type: 'desc'
            }
        ],
        page: 1, 
        perPage: 10
      },
      options_ricerca: [
        { value: null, text: 'seleziona...' },
        { value: 'name', text: 'Nome' },
        { value: 'surname', text: 'Cognome' },
        { value: 'email', text: 'Email' },
        { value: 'mobile', text: 'Cellulare' },
        { value: 'city', text: 'Città' },
        { value: 'zip', text: 'Cap' },
        /* 
        { value: 'provinces.name', text: 'Provincia' },
        */
      ],
      campiRicerca: {
        nome: '',
        sezione: null,
      },

      /* goodtable per Cancellati */
      show_loader_cancellati: true, 
      pageLength_cancellati: 10,
      totalRecords_cancellati: 0,
      columns_cancellati: [
        {
          label: 'Nome',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Cognome',
          field: 'surname',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Cellulare',
          field: 'mobile',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Provincia',
          field: 'province.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Data Reg.',
          field: 'datatime_agent_reg',
          width: '80px',
          formatFn: function (value) {
              return value != null ? moment(String(value)).locale('it').format('DD/MM/YYYY') : '---'
          },
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Provenienza',
          field: 'provenance.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Allineamento',
          field: 'align.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },

        {
          label: '',
          field: 'azioni',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      rows_cancellati: [],
      
      searchTerm_cancellati: '',
      resetState_cancellati: 'hidden',
      serverParams_cancellati: {
        columnFilters: {
          "deleted_at": {"operator": "<>", "value": null }
        },
        sort: [
            {
            field: 'id',
            type: 'desc'
            }
        ],
        page: 1, 
        perPage: 10
      },
      startServerParams_cancellati: {
        columnFilters: {
          "deleted_at": {"operator": "<>", "value": null }
        },
        sort: [
            {
            field: 'id',
            type: 'desc'
            }
        ],
        page: 1, 
        perPage: 10
      },
      options_ricerca_cancellati: [
        { value: null, text: 'seleziona...' },
        { value: 'name', text: 'Nome' },
        { value: 'surname', text: 'Cognome' },
        { value: 'email', text: 'Email' },
        { value: 'mobile', text: 'Cellulare' },
        { value: 'city', text: 'Città' },
        { value: 'zip', text: 'Cap' },
        /* 
        { value: 'provinces.name', text: 'Provincia' },
        */
      ],
      campiRicerca_cancellati: {
        nome: '',
        sezione: null,
      },
      /* goodtable per Cancellati */
    
      campiRicercaAvanzata: {
        allineamento: [ ],
        lingue: [ ],
        cap: [ ],
      },
      lista_cap: [],
      isLoadingCap: false,
      lista_aziende: [],
      isLoadingAziende: false,
      infoSideBar: {
        id: '',
        fullName: '',
        email: '',
        mobile_phone: '',
        phone: '',
        lista_licenze: [ ],
      },
      optionsLang: [ ],
      optionsAllineamento: [ ],
      optionsProfilo: [ ], 
      optionsSegmento: [ ], 
      optionsRegioni: [ ], 
      optionsAziende: [ ], 
      optionsTitoloDiStudio: [ ],
      Caricato: false,
      errore_caricamento: false,
      Caricato_Sidebar_ricerca_avanzata: false,
      errore_caricamento_ricerca_avanzata: false,
      options: [
        { days: '180', language: 'ultimi 6 mesi' },
        { days: '365', language: 'ultimo anno' },
        { days: '730', language: 'ultimi 2 anni' },
        { days: '1095', language: 'ultimi 3 anni' }
      ],
      titoloRicercaAvanzata: '',

      infoSideBar: {
          id: '', 
          ref_code: '', 
          customer_name: '',
          status_name: '', 
          type: '', 
          price: '', 
          contract_id: '', 
          contract_accepted_at: ''
      },
      listSourceOption: [ ],
      listStatusOption: [ ],
      Caricato: false,
      errore_caricamento: false,
      windowWidth: 0,
    }
  },
  mounted() {
      //in caso di resize del browser -> ricalcolo della larghezza della finestra
      window.onresize = () => {
          this.checkWindowsSizeAndResponsiveGoodTable();
      }
  },
  created() {
      this.checkWindowsSizeAndResponsiveGoodTable();

      //caricamento dei dati
      this.loadItems('Attivi');

      this.Caricato = true;
      this.errore_caricamento = false;
  },
  methods: {
    onTabChangeAttivi() {
      this.loadItems("Attivi");
    },
    onTabChangeCancellati(){
      this.loadItems("cancellati");
    },
    multiSelect_act_Lingua ({ value, text }) {
      return `${text}`
    },
    nameWithDays ({ days, language }) {
      return `${language} [${days} gg]`
    },
    asyncFindCap (query_cap) {
      //console.log("asyncFindCap ..... in corso, speriamo bene ...");
      let cap_ricerca_ok = query_cap.trim();

      if((cap_ricerca_ok !== '') && (cap_ricerca_ok.length >= 3)){    
        //si parte
        this.isLoadingCap = true
        
        //azzero le option dell'autosuggest dei cap
        this.lista_cap = [ ];

        this.$http.get('api/fs/cap/list/'+cap_ricerca_ok).then(response => { 
          if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
            //risposta positiva
            this.isLoadingCap = false;

            this.lista_cap = response.data.reply.data;
            //console.log(this.lista_cap)
          } else {
            //risposta negativa (errore sul server)
            if(response.data.reply.message != ''){
              this.$swal({
                  icon: 'warning',
                  title: 'Si è verificato un errore',
                  text: 'nella ricerca dei cap',
                  customClass: {
                  confirmButton: 'btn btn-outline-primary',
                  },
              })
            } else {
              this.$swal({
                  icon: 'error',
                  title: 'Si è verificato un errore generico',
                  text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                  customClass: {
                  confirmButton: 'btn btn-outline-primary',
                  },
              })
            }

            this.isLoadingCap = false;
          }
        }).catch(e => {
          console.log(e);
          this.isLoadingCap = false;
        });
      }         
    },
    asyncFindAziende(query_aziende) {
      //console.log("asyncFindAziende ...");
      let aziende_ricerca_ok = query_aziende.trim();

      if((aziende_ricerca_ok !== '') && (aziende_ricerca_ok.length >= 3)){    
        //si parte
        this.isLoadingAziende = true
        
        //azzero le option dell'autosuggest dei cap
        this.lista_aziende = [ ];

        this.$http.get('api/crm/agents/select/azienda/'+aziende_ricerca_ok).then(response => { 
          if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
            //risposta positiva
            this.isLoadingAziende = false;

            this.lista_aziende = response.data.reply;
            //console.log(this.lista_aziende)
          } else {
            //risposta negativa (errore sul server)
            if(response.data.reply.message != ''){
              this.$swal({
                  icon: 'warning',
                  title: 'Si è verificato un errore',
                  text: 'nella ricerca delle aziende',
                  customClass: {
                  confirmButton: 'btn btn-outline-primary',
                  },
              })
            } else {
              this.$swal({
                  icon: 'error',
                  title: 'Si è verificato un errore generico',
                  text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                  customClass: {
                  confirmButton: 'btn btn-outline-primary',
                  },
              })
            }

            this.isLoadingAziende = false;
          }
        }).catch(e => {
          console.log(e);
          this.isLoadingAziende = false;
        });
      }

    },

    checkWindowsSizeAndResponsiveGoodTable(){
      this.windowWidth = window.innerWidth

      if(window.innerWidth <1000){
        this.$set(this.columns[3], 'hidden', true)
        this.$set(this.columns[4], 'hidden', true)
      } else if(window.innerWidth <1200){
        this.$set(this.columns[3], 'hidden', false)
        this.$set(this.columns[4], 'hidden', false)
      } else if(window.innerWidth <1400){
        this.$set(this.columns[3], 'hidden', false)
        this.$set(this.columns[4], 'hidden', false)
      } else {
        //tutte le colonne visualizzate
        this.$set(this.columns[3], 'hidden', false)
        this.$set(this.columns[4], 'hidden', false)
      }
    },
    ricercaGenerale(tipo){
      if(
        (this.campiRicerca.sezione !== null) && (this.campiRicerca.sezione !== 'undefined')
        ||
        (this.campiRicerca_cancellati.sezione !== null) && (this.campiRicerca_cancellati.sezione !== 'undefined')
        ){
        console.log("ok ricerca valida -> in: "+tipo)
        
        let valore_ricerca = '';

        if(tipo == 'Attivi'){
          //conversione data nel formmato per la ricerca
          if(this.campiRicerca.sezione == 'subscription.created_at'){
            console.log("subscription.created_at PRIMA -> "+this.campiRicerca.nome)

            valore_ricerca = moment(this.campiRicerca.nome, ["DD/MM/YYYY"]).format("YYYY-MM-DD");

            console.log("subscription.created_at DOPO -> "+valore_ricerca)
          } else {
            //altri campi che NON hanno bisogno di pre-formattazione
            valore_ricerca = this.campiRicerca.nome;
          }
  
          //aggiorna parametri con la stessa funzione del filtro colonna ;-)
          this.updateParams(tipo, {
            columnFilters: {
              [this.campiRicerca.sezione]: valore_ricerca
            },
          });
        } else {
          //conversione data nel formmato per la ricerca
          if(this.campiRicerca_cancellati.sezione == 'cancellati.started_at'){
            valore_ricerca = moment(this.campiRicerca_cancellati.nome, ["DD/MM/YYYY"]).format("YYYY-MM-DD");
          } else if(this.campiRicerca_cancellati.sezione == 'cancellati.expires_at'){
            valore_ricerca = moment(this.campiRicerca_cancellati.nome, ["DD/MM/YYYY"]).format("YYYY-MM-DD");
          } else {
            //altri campi che NON hanno bisogno di pre-formattazione
            valore_ricerca = this.campiRicerca_cancellati.nome;
          }

          console.log("qui ...")
  
          //aggiorna parametri con la stessa funzione del filtro colonna ;-)
          this.updateParams(tipo, {
            columnFilters: {
              "deleted_at": {"operator": "<>", "value": null },
              [this.campiRicerca_cancellati.sezione]: valore_ricerca
            },
          });
        }

        //forza reset filtro colonne
        if(tipo == 'Attivi'){
          this.$refs.table_Attivi.reset();
        } else {
          this.$refs.table_Cancellati.reset();
        }

        this.loadItems(tipo);

        //attiva bottone "reset"
        if(tipo == 'Attivi'){
          this.resetState = 'attiva';
        } else {
          this.resetState_cancellati = 'attiva';
        }
      }
    },
    clickReset(tipo_licenza){
      console.log("clickReset -> "+tipo_licenza);

      if(tipo_licenza == 'Attivi'){
        //forza reset filtro colonne
        this.$refs.table_Attivi.reset();
  
        //forza reset parametri iniziali della tabella
        this.updateParams(tipo_licenza, this.startServerParams);
        this.loadItems(tipo_licenza);
  
        //reset campi ricerca
        this.campiRicerca.nome = '';
        this.campiRicerca.sezione = null;
        //disattiva bottone "reset"
        this.resetState = 'hidden';
      } else {
        //forza reset filtro colonne
        this.$refs.table_Cancellati.reset();
  
        //forza reset parametri iniziali della tabella
        this.updateParams(tipo_licenza, this.startServerParams_cancellati);
        this.loadItems(tipo_licenza);
  
        //reset campi ricerca
        this.campiRicerca_cancellati.nome = '';
        this.campiRicerca_cancellati.sezione = null;
        //disattiva bottone "reset"
        this.resetState_cancellati = 'hidden';
      }
    },
    ricercaAvanzataGo(){
      console.log("ricerca avanzata - chiamata Api per avere la ricerca su tutti della ricerca avanzata se diversi dal vuoto")
      console.log(this.campiRicercaAvanzata)
      
    },
    vuotaRicercaAvanzata(){
      //reset campi ricerca avanzata
      this.campiRicercaAvanzata = {};
    },
    sidebarAction(id, nome_cognome, email, mobile, provincia, align, vat, enasarco, deleted_at) {
      this.infoSideBar = { id: id,
        titolo: nome_cognome,
        email: email,
        cellulare: mobile,
        provincia: provincia,
        align: align,
        vat: vat, 
        enasarco: enasarco, 
        deleted_at: deleted_at };
        
      this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
    },
    sidebarAdvancedSearch(tipo){
      //setto filtro per stato agente così da utilizzarlo per manipolare i parametri primi di fare la chiama all'api
      this.campiRicercaAvanzata.tipoRicerca = tipo
      this.titoloRicercaAvanzata = 'Agenti ' + tipo + ' - Ricerca Avanzata';

      this.$root.$emit('bv::toggle::collapse', 'sidebar-advanced-search')

      //estrazione dati per popolare le select secce (Lingua e Allineamento)
      const langPromise = this.$http.get('api/fs/lang/list')
      const AllinemanetoPromise = this.$http.get('api/crm/agents/select/allineamento')
      const ProfiloPromise = this.$http.get('api/crm/agents/select/profilo')
      const SegmentoPromise = this.$http.get('api/crm/agents/select/segmento')
      const RegioniPromise = this.$http.get('api/fs/regions/list')
      const SettoriPromise = this.$http.get('api/crm/agents/select/settore')
      const ProvincePromise = this.$http.get('api/fs/province/list')
      const TitoloDistudioPromise = this.$http.get('api/crm/agents/select/titolostudio')

      Promise.all([langPromise, AllinemanetoPromise,ProfiloPromise,SegmentoPromise,
      RegioniPromise,SettoriPromise,ProvincePromise,TitoloDistudioPromise]).then(response => {
        if(response[0].data.statusCode===200){
          //caricamento lista Lingue
          this.optionsLang = response[0].data.reply.data

          if(response[1].data.statusCode===200){
            //caricamento lista Allinemaneto
            this.optionsAllineamento = response[1].data.reply

            if(response[2].data.statusCode===200){
              //caricamento lista Profilo
              this.optionsProfilo = response[2].data.reply

              if(response[3].data.statusCode===200){
                //caricamento lista Segmento
                this.optionsSegmento = response[3].data.reply

                if(response[4].data.statusCode===200){
                  //caricamento lista Regioni
                  this.optionsRegioni = response[4].data.reply

                  if(response[5].data.statusCode===200){
                    //caricamento lista Settori
                    this.optionsSettori = response[5].data.reply

                    if(response[6].data.statusCode===200){
                    //caricamento lista province
                      this.optionsProvince = response[6].data.reply

                      if(response[7].data.statusCode===200){
                      //caricamento lista province
                        this.optionsTitoloDiStudio = response[7].data.reply

                        this.Caricato_Sidebar_ricerca_avanzata = true;
                        this.errore_caricamento_ricerca_avanzata = false;

                      } else {
                        this.Caricato_Sidebar_ricerca_avanzata = false;
                        this.errore_caricamento_ricerca_avanzata = true;
                      }

                    } else {
                      this.Caricato_Sidebar_ricerca_avanzata = false;
                      this.errore_caricamento_ricerca_avanzata = true;
                    }

                  } else {
                    this.Caricato_Sidebar_ricerca_avanzata = false;
                    this.errore_caricamento_ricerca_avanzata = true;
                  }

                } else {
                  this.Caricato_Sidebar_ricerca_avanzata = false;
                  this.errore_caricamento_ricerca_avanzata = true;
                }

              } else {
                this.Caricato_Sidebar_ricerca_avanzata = false;
                this.errore_caricamento_ricerca_avanzata = true;
              }

            } else {
              this.Caricato_Sidebar_ricerca_avanzata = false;
              this.errore_caricamento_ricerca_avanzata = true;
            }
          } else {
            this.Caricato_Sidebar_ricerca_avanzata = false;
            this.errore_caricamento_ricerca_avanzata = true;
          }      
        } else {
          this.Caricato_Sidebar_ricerca_avanzata = false;
          this.errore_caricamento_ricerca_avanzata = true;
        }
      })
    },
    
    updateParams(tipo_licenza, newProps) {
      console.log("GENERALE updateParams per ..... "+tipo_licenza+"...............")
      console.log(newProps)
      console.log("newProps.columnFilters.type -> "+tipo_licenza)

      if(tipo_licenza == 'Attivi'){
        //attiva bottone "reset" filtro o ricerca
        this.resetState = 'attiva';

        this.serverParams = Object.assign({}, this.serverParams, newProps);
      } else {
        //attiva bottone "reset" filtro o ricerca
        this.resetState_cancellati = 'attiva';

        this.serverParams_cancellati = Object.assign({}, this.serverParams_cancellati, newProps);
      }
    },
    
    onPageChange_Attivi(params) {
      this.updateParams('Attivi', {page: params.currentPage});
      this.loadItems('Attivi');
    },
    onPageChange_Cancellati(params) {
      this.updateParams('Cancellati', {page: params.currentPage});
      this.loadItems('Cancellati');
    },

    onPerPageChange_Attivi(params) {
      this.updateParams('Attivi', {perPage: params.currentPerPage});
      this.loadItems('Attivi');
    },
    onPerPageChange_Cancellati(params) {
      this.updateParams('Cancellati', {perPage: params.currentPerPage});
      this.loadItems('Cancellati');
    },

    onSortChange_Attivi(params) {
      //console.log('cambio ordinamento')
      //console.log(params)

      let field_ricerca = '';

      //colonne con relazione
      if(params[0].field == 'source.text'){
        field_ricerca = 'source.name';
      } else {
        field_ricerca = params[0].field;
      }

      this.updateParams('Attivi', {
        sort: [{
          type: params[0].type,
          field: field_ricerca,
        }],
      });
      this.loadItems('Attivi');
    },
    onSortChange_Cancellati(params) {
      let field_ricerca = '';

      //colonne con relazione
      if(params[0].field == 'source.text'){
        field_ricerca = 'source.name';
      } else {
        field_ricerca = params[0].field;
      }

      this.updateParams('Cancellati', {
        sort: [{
          type: params[0].type,
          field: field_ricerca,
        }],
      });
      this.loadItems('Cancellati');
    },
    
    onColumnFilter_Attivi(params) {
      console.log("in ->>>> onColumnFilter_Attivi")
      console.log(params)
      
      this.updateParams('Attivi', params);
      this.loadItems('Attivi');
    },

    onColumnFilter_Cancellati(params) {
      console.log("in ->>>> onColumnFilter_Cancellati")
      console.log(params)
      
      this.updateParams('Cancellati', params);
      this.loadItems('Cancellati');
    },

    loadItems(tipo = 'Attivi') {
      this.isLoading = true;

      //in base a stato agente
      if(tipo == 'Attivi'){

        this.$http.get('api/crm/agents/list', {
          params: this.serverParams 
          }).then(response => { 
              if(response.data.statusCode == 200){
                //ok
                this.totalRecords = response.data.reply.meta.total;
                this.rows = response.data.reply.data;
                
              } else if(response.data.statusCode == 422){
                //errori con testo di notifica ok dall'api
                this.$swal({
                  title: 'Errore',
                  text: 'codice errore: '+response.data.reply,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    textButton: 'Chiudi',
                  },
                  buttonsStyling: false,
                })
              } else {
                //tutto gli altri possibili errori
                this.$swal({
                  title: 'Errore Generico',
                  text: 'codice errore: '+response.data.statusCode,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    textButton: 'Chiudi',
                  },
                  buttonsStyling: false,
                })
              }
              
              //rimuovi il loading dopo il caricamento dei dati nella tabella
              this.isLoading = false;
          
          })
      } else {
        //cancellati

        this.$http.get('api/crm/agents/list', {
          params: this.serverParams_cancellati 
          }).then(response => { 
              if(response.data.statusCode == 200){
                //ok
                this.totalRecords_cancellati = response.data.reply.meta.total;
                this.rows_cancellati = response.data.reply.data;
                
              } else if(response.data.statusCode == 422){
                //errori con testo di notifica ok dall'api
                this.$swal({
                  title: 'Errore',
                  text: 'codice errore: '+response.data.reply,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    textButton: 'Chiudi',
                  },
                  buttonsStyling: false,
                })
              } else {
                //tutto gli altri possibili errori
                this.$swal({
                  title: 'Errore Generico',
                  text: 'codice errore: '+response.data.statusCode,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    textButton: 'Chiudi',
                  },
                  buttonsStyling: false,
                })
              }
              
              //rimuovi il loading dopo il caricamento dei dati nella tabella
              this.isLoading = false;
          
          })
      }

    },
    formattazioneOrario(value, stringa){
      return moment(String(value)).locale('it').format(stringa)
    },
    cancellaRiga(id_riga){
      //console.log("click cancellaRiga ...... id_riga -> "+id_riga);

      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "conferma la cancellazione dell'agente",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.post('api/crm/agents/delete/'+id_riga)
            .then(response => { 
                //console.log("risposta server --------")
                //console.log(response.data)

                if(response.data.statusCode===200){
                  //risposta positiva
                  this.$swal({
                      icon: 'success',
                      title: response.data.reply.reply,
                      //text: 'operazione effettuata correttamente',
                      confirmButtonText: 'chiudi',
                      customClass: {
                      confirmButton: 'btn btn-success',
                      },
                  })

                  //chiudi sidebar
                  this.$refs.sidebar_azioni.hide();

                  //aggiorna tabella
                  this.loadItems('Attivi');
                    
                } else {
                //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    },
    ripristinaRiga(id_riga){
      //console.log("click ripristinaRiga ...... id_riga -> "+id_riga);

      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "conferma la riattivazione dell'agente",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-primary',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.post('api/crm/agents/recover/'+id_riga)
            .then(response => { 
                //console.log("risposta server --------")
                //console.log(response.data)

                if(response.data.statusCode===200){
                  //risposta positiva
                  this.$swal({
                      icon: 'success',
                      title: response.data.reply.reply,
                      confirmButtonText: 'chiudi',
                      customClass: {
                      confirmButton: 'btn btn-success',
                      },
                  })

                  //chiudi sidebar
                  this.$refs.sidebar_azioni.hide();

                  //aggiorna tabella
                  this.loadItems('Cancellati');
                    
                } else {
                //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    },
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-multiselect.min.scss';

.riga_sidebar{
  padding-bottom: 5px;
}
</style>
